<template>
    <v-container fluid class="fill-height text-center ma-0 pa-0">
        <v-row>
            <v-col cols="12">
                <v-sheet
                        class="mx-auto"
                        max-width="100%">
                    <v-img src="@/assets/images/carta-cocteles-restaurante.jpg"
                           class="grey lighten-2"
                           width="100%" height="550"
                           gradient="to bottom, rgba(0,0,0,.3), rgba(0,0,0,.7)">
                        <v-container fluid class="fill-height bottom-gradient ma-0">
                            <v-row class="mt-5">
                                <v-col cols="12" md="6">
                                    <!--<div class="fill-height ml-10 mr-5 zoom-img">-->
                                    <div class="fill-height ml-10 mr-5">
                                        <v-card
                                                class="rounded-lg zoom-image"
                                                height="auto"
                                                width="100%"
                                                outlined
                                                hover
                                                elevation="10"
                                                to="/restaurants">
                                            <v-img  src="@/assets/images/ejemplo-de-anuncio-restaurante.jpg"
                                                    :lazy-src="require('@/assets/images/no-image.jpg')"
                                                    class="white--text align-end justify-start zoom-image"
                                                    position="center"
                                                    height="450"
                                                    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.3)">
                                                <div class="white--text mx-2">
                                                    <p class="text-left font-weight-light text-h4">
                                                        Tenga una experiencia única en comida fina a través
                                                        de nuestro catálogo de restaurantes.
                                                    </p>
                                                </div>
                                            </v-img>
                                        </v-card>
                                    </div>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <div class="fill-height ml-5 mr-10">
                                        <v-card
                                                class="rounded-lg zoom-image"
                                                height="auto"
                                                width="100%"
                                                outlined
                                                hover
                                                elevation="10"
                                                to="/leisures">
                                            <v-img  src="@/assets/images/leisures-568x492.jpg"
                                                    :lazy-src="require('@/assets/images/no-image.jpg')"
                                                    class="white--text align-end justify-start zoom-image"
                                                    position="center"
                                                    height="450"
                                                    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.3)">
                                                <div class="white--text mx-2">
                                                    <p class="text-left font-weight-light text-h4">
                                                        Descubra nuevos horizontes y visite nuestros mejores
                                                        lugares para el ocio y el entretenimiento.
                                                    </p>
                                                </div>
                                            </v-img>
                                        </v-card>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-img>
                </v-sheet>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "RestaurantsLeisuresComponent"
    }
</script>

<style scoped>
    .zoom-img {
        overflow: hidden;
        border-radius: 10px;
    }
    .zoom-img > img {
        object-fit: cover;
        transition: all .3s ease-in-out;
    }
    .zoom-img:hover > img {
        transform: scale(1.05);
    }
    .zoom-image {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
        /* VERZÖGERUNG */
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
        -ms-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
        transition: all 0.3s linear;
        overflow: hidden;
    }
    .zoom-image:hover {
        -webkit-transform: scale(1.07);
        -moz-transform: scale(1.07);
        -ms-transform: scale(1.07);
        -o-transform: scale(1.07);
        transform: scale(1.07);
    }
    .blend-opacity {
        opacity: 0.2;
        transition: opacity 0.4s ease-in-out;
        background-color: black;
        background-blend-mode: normal;
    }
    .blend-opacity:hover {
        opacity: 0.5;
        transition: opacity 0.4s ease-in-out;
        background-color: black;
        background-blend-mode: darken;
    }
</style>